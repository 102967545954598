import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import CardCounterSimple from '../components/CardCounter/CardCounterSimple';

const Price = () => {
    return(
        <div className="body_wrapper">
            {/* <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/> */}
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="cardcounter/poker.jpg" Ptitle="How to Use CardCount.io" Pdescription="Play Blackjack for fun or experimentation and then simply select how many decks are in use and record the cards as you play by selecting the appropriate button. Make sure you start on a fresh deck (shoe). Click reset to reset your numbers. **For learning purposes only**."/>
            <CardCounterSimple />
        </div>
    )
}
export default Price;