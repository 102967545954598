import React from "react";
const NotFound = () => (
  <div className="body_wrapper">
    <section className="error_two_area">
      <div className="container flex">
        <div className="error_content_two text-center">
          <img style={{marginTop: '1em', width: '40%', height: '40%'}} src={require("../img/cardcounter/error.png")} alt="" />
          <h2 style={{color: 'black'}}>Error. We can’t find the page you’re looking for.</h2>
          <p style={{color: '#3f3f3f'}}>
            Sorry for the inconvenience.
          </p>
          <a href="/" style={{background: 'black'}} className="about_btn btn_hover">
            Home <i className="arrow_right"></i>
          </a>
        </div>
      </div>
    </section>
  </div>
);
export default NotFound;
